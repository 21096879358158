import React from "react"
import ChikooLogo from "../assets/logo/chikoo_orange.png"
import ChikooSmallLogo from "../assets/logo/chikoo_small.png"
import "./common.css"

const Logo = ({ small = false }) => {
  return (
    <img
      src={small ? ChikooSmallLogo : ChikooLogo}
      className="logo"
      alt="logo"
    />
  )
}
export default Logo
