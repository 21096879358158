import { createBrowserRouter, Navigate } from "react-router-dom"
import Dashboard from "../pages/dashboard/Dashboard"
import Main from "../pages/layout/Main"
import Login from "../pages/login/Login"
import Orders from "../pages/orders/Orders"
import Users from "../pages/users/Users"

export const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />
  },
  {
    path: "/",
    element: <Main />,
    children: [
      {
        path: "/",
        element: <Dashboard />
      },
      {
        path: "/users",
        element: <Users />
      },
      {
        path: "/orders",
        element: <Orders />
      }
    ]
  },
  {
    path: "*",
    element: <Navigate to="/login" replace />
  }
])
