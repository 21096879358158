import React from "react"
import {
  DatabaseOutlined,
  UserOutlined,
  DashboardOutlined
} from "@ant-design/icons"

export const MENU_ITEMS = [
  {
    route: "/",
    icon: React.createElement(DashboardOutlined),
    label: "Dashboard"
  },
  {
    route: "/users",
    icon: React.createElement(UserOutlined),
    label: "Users"
  },
  {
    route: "/orders",
    icon: React.createElement(DatabaseOutlined),
    label: "Orders"
  }
]
