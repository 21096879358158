import { gql, useQuery } from "@apollo/client"
import { Alert, Table, Tag } from "antd"
import React, { FC } from "react"
import type { ColumnsType } from "antd/es/table"
import { Order } from "./order_types"
import { getDateAndTime, getDisplayPrice } from "../../utils/HelperFunctions"

const Orders: FC = () => {
  const { data, loading, error, refetch } = useQuery(GET_ORDERS)

  const columns: ColumnsType<Order> = [
    {
      title: "Order Number",
      dataIndex: "orderNumber",
      key: "orderNumber"
    },
    {
      title: "Order Status",
      dataIndex: "orderStatus",
      key: "orderStatus",
      render: (_, { orderStatus }) => <Tag>{orderStatus}</Tag> || "N/A"
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, { createdAt }) => getDateAndTime(createdAt)
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      key: "paymentType",
      render: (_, { paymentType }) => <Tag>{paymentType}</Tag> || "N/A"
    },
    {
      title: "Order Source",
      dataIndex: "orderSource",
      key: "orderSource",
      render: (_, { orderSource }) => <Tag>{orderSource}</Tag> || "N/A"
    },

    {
      title: "Delivery Charges",
      dataIndex: "deliveryCharges",
      key: "deliveryCharges",
      render: (_, { deliveryCharges }) => getDisplayPrice(deliveryCharges)
    },

    {
      title: "Tax Amount",
      dataIndex: "taxAmount",
      key: "taxAmount",
      render: (_, { taxAmount }) => getDisplayPrice(taxAmount)
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (_, { totalAmount }) => getDisplayPrice(totalAmount)
    },
    {
      title: "Merchant",
      dataIndex: "merchant",
      key: "merchant",
      render: (_, { merchant }) => merchant.storeName
    }
  ]

  const onChange = (page: number, pageSize?: number) => {
    refetch({
      offset: (page - 1) * (pageSize || 10),
      size: pageSize || 10
    })
  }

  return (
    <>
      {error && (
        <Alert
          message={error?.message || "Something went wrong"}
          type="error"
        />
      )}

      <Table
        pagination={{
          pageSize: 10,
          onChange: onChange,
          total: 200000,
          showSizeChanger: false
        }}
        title={() => "Chikoo Orders"}
        loading={loading}
        columns={columns}
        dataSource={data?.orders}
      />
    </>
  )
}

export default Orders

const GET_ORDERS = gql`
  query orders(
    $dateFilter: DateFilter
    $status: [OrderStatus]
    $offset: Int
    $size: Int
  ) {
    orders(
      dateFilter: $dateFilter
      status: $status
      offset: $offset
      size: $size
    ) {
      bvid
      orderNumber
      orderStatus
      createdAt
      paymentType
      orderStatus
      orderSource
      subTotal
      deliveryCharges
      totalAmount
      taxAmount
      merchant {
        bvid
        storeName
      }
    }
  }
`
