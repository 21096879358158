export const keys = {
  USER: "user",
  BRANCH: "branch"
}
export const addToStorage = (key: string, data: any) => {
  data = JSON.stringify(data)
  localStorage.setItem(key, data)
}

export const getFromStorage = (key: string) => {
  const user = localStorage.getItem(key)
  return user
}

export const removeFromStorage = (key: string) => {
  localStorage.removeItem(key)
}
