import { gql, useQuery } from "@apollo/client"
import { Space, Alert, Tag, Table, Button, Tooltip } from "antd"
import React, { FC } from "react"
import { User } from "./user_types"
import type { ColumnsType } from "antd/es/table"
import { EditOutlined, DeleteOutlined } from "@ant-design/icons"

const Users: FC = () => {
  const { data, loading, error } = useQuery(GET_USERS)

  const columns: ColumnsType<User> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email"
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone"
    },
    {
      title: "Role",
      key: "role",
      dataIndex: "role",
      render: (_, { role }) => <Tag>{role}</Tag>
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <Button icon={<EditOutlined />} />
          </Tooltip>
          <Tooltip title="Delete">
            <Button type="text" icon={<DeleteOutlined />} danger />
          </Tooltip>
        </Space>
      )
    }
  ]

  return (
    <>
      {error && (
        <Alert
          message={error?.message || "Something went wrong"}
          type="error"
        />
      )}

      <Table
        title={() => "Admin Panel Users"}
        loading={loading}
        columns={columns}
        dataSource={data?.users}
      />
    </>
  )
}

export default Users

const GET_USERS = gql`
  query {
    users {
      bvid
      name
      email
      role
      phone
    }
  }
`
