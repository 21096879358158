const formatDate = (timestamp: any) => {
  const date = new Date(timestamp)
  return date.toLocaleString("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric"
  })
}

const formatTime = (timestamp: any) => {
  const date = new Date(timestamp)
  return date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true
  })
}

export const getDisplayPrice = (price: number) => {
  if (price === 0) return "Rs. 0"
  return price === 0 ? "Rs N/A" : `Rs. ${Math.round(price).toLocaleString()}`
}

export const getDateAndTime = (when: any) => {
  const date = new Date(when * 1000)
  return `${formatDate(date)} ${formatTime(date)}`
}
