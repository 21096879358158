import React, { FC } from "react"
import "antd/dist/reset.css"
import "./App.css"
import { ConfigProvider } from "antd"
import theme from "./styles/theme.json"
import { RouterProvider } from "react-router-dom"
import { router } from "./router/Router"
import { ApolloProvider } from "@apollo/client"
import sniperClient from "./graphql/sniper"

const App: FC = () => (
  <React.StrictMode>
    <ApolloProvider client={sniperClient}>
      <ConfigProvider theme={theme}>
        <RouterProvider router={router} />
      </ConfigProvider>
    </ApolloProvider>
  </React.StrictMode>
)

export default App
