import React, { FC, useState } from "react"
import { Layout, Menu, Row } from "antd"
import { Outlet, useNavigate } from "react-router-dom"

import Logo from "../../common/CommonLogo"
import "./main.css"
import { MENU_ITEMS } from "./MenuItems"
import LogoutOutlined from "@ant-design/icons/LogoutOutlined"

const { Content, Sider } = Layout

const Main: FC = () => {
  const navigate = useNavigate()
  const [collapsed, setCollapsed] = useState(false)

  function logout() {
    localStorage.clear()
    navigate("/login")
  }

  const createMenuList = () => {
    return [
      ...MENU_ITEMS.map((item, index) => ({
        key: String(index + 1),
        icon: item.icon,
        label: item.label,
        onClick: () => navigate(item.route)
      })),
      {
        key: MENU_ITEMS.length + 1,
        icon: <LogoutOutlined />,
        label: "Logout",
        onClick: logout
      }
    ]
  }
  return (
    <Layout className="layout-container">
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <Row justify="center" className="logo-bar">
          <Logo small={collapsed} />
        </Row>
        <Menu
          theme="dark"
          mode="vertical"
          defaultSelectedKeys={["1"]}
          items={createMenuList()}
        />
      </Sider>
      <Layout>
        <Content className="content">
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  )
}

export default Main
