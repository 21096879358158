import React, { FC, useEffect, useState } from "react"
import { Alert, Button, Form, Input, Col, Row, Space, Card } from "antd"
import Title from "antd/es/typography/Title"
import Text from "antd/es/typography/Text"
import Logo from "../../common/CommonLogo"
import "./login.css"
import { gql, useMutation } from "@apollo/client"
import { useNavigate } from "react-router-dom"

const Login: FC = () => {
  const navigate = useNavigate()

  const [errorMessage, setErrorMessage] = useState("")

  const [login, { loading: loginLoading, data: loginData, error: loginError }] =
    useMutation(LOGIN_USER)

  useEffect(() => {
    if (loginData?.loginUser?.token) {
      localStorage.setItem("token", loginData.loginUser.token)
      localStorage.setItem("user", JSON.stringify(loginData.loginUser.user))
      navigate("/")
    }
  }, [loginData, navigate])

  useEffect(() => {
    if (loginError) {
      setErrorMessage(
        loginError?.message ||
          "Something went wrong. Please check your credentials and try again."
      )
    }
  }, [loginError])

  const onFinish = async (values: any) => {
    await login({
      variables: {
        email: values.email,
        password: values.password
      }
    })
  }

  return (
    <Row className="form-login" align="middle">
      <Col span={8} push={8}>
        <Card>
          <Space size="large" direction="vertical" style={{ display: "flex" }}>
            <Row justify="space-between" align="middle">
              <Col span={12}>
                <Title className="title" level={3}>
                  Welcome
                </Title>
                <Text type="secondary">Please login into your account </Text>
              </Col>
              <Col span={12} className="align-right">
                <Logo />
              </Col>
            </Row>

            <Form
              layout="vertical"
              name="login"
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { type: "email", message: "Please enter a valid email." },
                  { required: true, message: "Please input your email!" }
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" }
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item>
                <Button
                  className="login-button"
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={loginLoading}
                >
                  Login
                </Button>
              </Form.Item>
            </Form>
            {errorMessage && <Alert message={errorMessage} type="error" />}
          </Space>
        </Card>
      </Col>
    </Row>
  )
}

export default Login

const LOGIN_USER = gql`
  mutation LoginUser($email: String!, $password: String!) {
    loginUser(email: $email, password: $password) {
      token
      user {
        bvid
        name
        role
      }
    }
  }
`
