import React, { FC } from "react"
import Title from "antd/es/typography/Title"

const Dashboard: FC = () => (
  <>
    <Title>Dashboard </Title>
  </>
)

export default Dashboard
